import React from "react"
import { graphql } from "gatsby"

import { Address4, Address6 } from "ip-address"

import ToolLayout from "../../../components/tool_layout"
import PipeWidget from "../../../components/widgets/pipe"

export const query = graphql`
  query Ipv4ToIpv6Query {
    allToolsJson(filter: { path: { eq: "net/ipv4-to-ipv6" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class Ipv4ToIpv6Page extends React.Component<{ data: any }> {
  render() {
    const transformer = value => {
      const address = Address6.fromAddress4(value)
      return address.canonicalForm()
    }

    const validate_ipv4 = value => {
      if (value.length === 0) {
        return null
      }

      const address = new Address4(value)
      if (!address.isValid()) {
        return "Invalid IPv4 address"
      }
    }

    return (
      <ToolLayout data={this.props.data}>
        <PipeWidget
          transformer={transformer}
          exceptionHandler={e => {
            if (e) {
              return "Invalid IPv4 address"
            }
          }}
          inputProps={{
            placeHolderText: "Input IPv4 address here",
            validator: validate_ipv4,
          }}
          outputProps={{
            placeHolderText: "IPv6 address will be shown here",
          }}
        />
      </ToolLayout>
    )
  }
}

export default Ipv4ToIpv6Page
